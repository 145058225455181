export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export function closestEquivalentAngle(from: number, to: number) {
	var delta = ((((to - from) % 360) + 540) % 360) - 180;
	return from + delta;
}

export function generateCustomizeSize(speed: number) {
	return [
		speed >= 2 ? 40 : speed >= 4 ? 44 : speed >= 6 ? 48 : 36,
		speed >= 2 ? 48 : speed >= 4 ? 52 : speed >= 6 ? 56 : 44,
	]
}

// export function generateCustomizeSize(speed: number) {
// 	return [
// 		speed > 1 ? 28 : speed > 3 ? 34 : speed > 5 ? 38 : 20,
// 		speed > 1 ? 38 : speed > 3 ? 44 : speed > 5 ? 48 : 35,
// 	]
// }

import shipIcon from "../components/icon/new-icons/ship/towing.svg";
import {
  bstSvg,
  bstPkkSvg,
  bstForSvg,
  bstForPkkSvg,
  cargoSvg,
  cargoPkkSvg,
  cargoForSvg,
  cargoForPkkSvg,
  fishingSvg,
  fishingPkkSvg,
  fishingForSvg,
  fishingForPkkSvg,
  highSpeedSvg,
  highSpeedPkkSvg,
  highSpeedForSvg,
  highSpeedForPkkSvg,
  militarySvg,
  militaryPkkSvg,
  militaryForSvg,
  militaryForPkkSvg,
	motionLessSvg,
	motionLessForSvg,
  passengerSvg,
  passengerPkkSvg,
  passengerForSvg,
  passengerForPkkSvg,
  rescueSvg,
  rescuePkkSvg,
  rescueForSvg,
  rescueForPkkSvg,
  specialSvg,
  specialPkkSvg,
  specialForSvg,
  specialForPkkSvg,
  tankerSvg,
  tankerPkkSvg,
  tankerForSvg,
  tankerForPkkSvg,
  towingSvg,
  towingPkkSvg,
  towingForSvg,
  towingForPkkSvg,
  tugSvg,
  tugPkkSvg,
  tugForSvg,
  tugForPkkSvg,
  unspecifiedSvg,
  unspecifiedPkkSvg,
  unspecifiedForSvg,
  unspecifiedForPkkSvg,
  wingSvg,
  wingPkkSvg,
  wingForSvg,
  wingForPkkSvg
} from "../components/icon/new-icons";

export const dummyData = () => {
	let data = [
		{
				"id": 34491178,
				"sensor_data_id": 43525144,
				"vessel_id": 8888,
				"latitude": "-6.1255",
				"longitude": "106.8456",
				"speed": "5.20",
				"course": 360,
				"heading": 359,
				"navigation_status": "Under way using engine",
				"turning_rate": 128,
				"turning_direction": 0,
				"timestamp": "2025-03-19 06:00:00",
				"distance": "230.11",
				"is_inside_geofence": 0,
				"is_geofence": 0,
				"created_at": "2025-03-19T06:00:00.000000Z",
				"updated_at": "2025-03-19T06:00:00.000000Z",
				"vessel": {
				  "id": 8888,
				  "vessel_name": "TB LEO 2212",
				  "vessel_type": "Tug",
				  "mmsi": "525232087",
				  "imo": 8568149,
				  "callsign": "YDA3977",
				  "draught": 0,
				  "dimension_to_bow": "8",
				  "dimension_to_stern": "21",
				  "dimension_to_port": "5",
				  "dimension_to_starboard": "3",
				  "reported_destination": null,
				  "out_of_range": 0,
				  "type_number": null,
				  "reported_eta": "2024-11-29 16:00:00",
				  "no_pkk": null,
				  "jenis_layanan": null,
				  "nama_negara": null,
				  "tipe_kapal": null,
				  "nama_perusahaan": null,
				  "tgl_tiba": null,
				  "tgl_brangkat": null,
				  "bendera": null,
				  "gt_kapal": null,
				  "dwt": null,
				  "nakhoda": null,
				  "jenis_trayek": null,
				  "pelabuhan_asal": null,
				  "pelabuhan_tujuan": null,
				  "lokasi_lambat_labuh": null,
				  "nomor_spog": null,
				  "jenis_muatan": null,
				  "no_pandu": null,
				  "nama_pandu": null,
				  "nama_kapal_eks": null,
				  "nama_kapal_pemilik": null,
				  "loa": null,
				  "counting_hari": 1,
				  "tgl_counting_hari": "2024-12-18",
				  "last_update_counting": "2025-02-15",
				  "isAssign": 0,
				  "nama_kapal_inaportnet": null,
				  "created_at": "2024-12-18T13:09:14.000000Z",
				  "updated_at": "2025-02-15T21:14:36.000000Z"
				},
				"sensor_data": {
				  "id": 43515144,
				  "sensor_id": 1,
				  "payload": "!BSVDM,1,1,,A,17lobmwP007P:n5vDn`N4?w@08H2,0*28\r\n",
				  "timestamp": "2025-03-19 06:00:00",
				  "created_at": "2025-03-19T06:00:00.000000Z",
				  "updated_at": "2025-03-19T06:00:00.000000Z",
				  "sensor": {
					"id": 1,
					"datalogger_id": 1,
					"name": "AIS",
					"status": "Active",
					"interval": 5,
					"jarak": 20,
					"jumlah_data": 200,
					"created_at": "2024-12-17T08:34:54.000000Z",
					"updated_at": "2024-12-17T08:34:54.000000Z",
					"latitude": "",
					"longitude": "",
					"altitude": "",
					"kompas": "",
					"datalogger": {
					  "id": 1,
					  "name": "Datalogger 1",
					  "serial_number": "DL001",
					  "latitude": "-6.2256992",
					  "longitude": "106.8503082",
					  "status": "Online",
					  "installation_date": "2022-01-01",
					  "last_online": "2023-05-24 12:34:56",
					  "coverage": null,
					  "pelabuhan_id": null,
					  "created_at": "2024-12-17T08:34:54.000000Z",
					  "updated_at": "2024-12-17T08:34:54.000000Z"
					}
				  }
				}
			  
		}
	]
	return data;
}

export const positionCSS = (course: any) => {
	if(course >= 0 && course <= 10) {
		return `moving-ship-icon-10`;
	} else if(course > 10 && course <= 20) {
		return `moving-ship-icon-20`;
	} else if(course > 20 && course <= 30) {
		return `moving-ship-icon-30`;
	} else if(course > 30 && course <= 40) {
		return `moving-ship-icon-40`;
	} else if(course > 40 && course <= 50) {
		return `moving-ship-icon-50`;
	} else if(course > 50 && course <= 60) {
		return `moving-ship-icon-60`;
	} else if(course > 60 && course <= 70) {
		return `moving-ship-icon-70`;
	} else if(course > 70 && course <= 80) {
		return `moving-ship-icon-80`;
	} else if(course > 80 && course <= 90) {
		return `moving-ship-icon-90`;
	} else if(course > 90 && course <= 100) {
		return `moving-ship-icon-100`;
	} else if(course > 100 && course <= 110) {
		return `moving-ship-icon-110`;
	} else if(course > 110 && course <= 120) {
		return `moving-ship-icon-120`;
	} else if(course > 120 && course <= 130) {
		return `moving-ship-icon-130`;
	} else if(course > 130 && course <= 140) {
		return `moving-ship-icon-140`;
	} else if(course > 140 && course <= 150) {
		return `moving-ship-icon-150`;
	} else if(course > 150 && course <= 160) {
		return `moving-ship-icon-160`;
	} else if(course > 160 && course <= 170) {
		return `moving-ship-icon-170`;
	} else if(course > 170 && course <= 180) {
		return `moving-ship-icon-180`;
	} else if(course > 180 && course <= 190) {
		return `moving-ship-icon-190`;
	} else if(course > 190 && course <= 200) {
		return `moving-ship-icon-200`;
	} else if(course > 200 && course <= 210) {
		return `moving-ship-icon-210`;
	} else if(course > 210 && course <= 220) {
		return `moving-ship-icon-220`;
	} else if(course > 220 && course <= 230) {
		return `moving-ship-icon-230`;
	} else if(course > 230 && course <= 240) {
		return `moving-ship-icon-240`;
	} else if(course > 240 && course <= 250) {
		return `moving-ship-icon-250`;
	} else if(course > 250 && course <= 260) {
		return `moving-ship-icon-260`;
	} else if(course > 260 && course <= 270) {
		return `moving-ship-icon-270`;
	} else if(course > 270 && course <= 280) {
		return `moving-ship-icon-280`;
	} else if(course > 280 && course <= 290) {
		return `moving-ship-icon-290`;
	} else if(course > 290 && course <= 300) {
		return `moving-ship-icon-300`;
	} else if(course > 300 && course <= 310) {
		return `moving-ship-icon-310`;
	} else if(course > 310 && course <= 320) {
		return `moving-ship-icon-320`;
	} else if(course > 320 && course <= 330) {
		return `moving-ship-icon-330`;
	} else if(course > 330 && course <= 340) {
		return `moving-ship-icon-340`;
	} else if(course > 340 && course <= 350) {
		return `moving-ship-icon-350`;
	} else if(course > 350 && course <= 360) {
		return `moving-ship-icon-360`;
	}

	return '';
}

export const getShipIcon = (shipType?: string, mmsi?: string, no_pkk?: string, speed?: number) => {
	if (Number(speed) === 0) {
		if (mmsi && mmsi.slice(0, 3) === "525") {
			return motionLessSvg
		} else {
			return motionLessForSvg
		}
	}
  if (!shipType) return shipIcon;
	
	let isIndonesia = false
	if (mmsi && mmsi.slice(0, 3) === "525") {
		isIndonesia = true
	}
	let isPkk = false
	if (no_pkk) {
		isPkk = true
	}

	const shipTypes = [
		{ type: "passenger", domestic: passengerSvg, domesticPkk: passengerPkkSvg, foreign: passengerForSvg, foreignPkk: passengerForPkkSvg },
		{ type: "fishing", domestic: fishingSvg, domesticPkk: fishingPkkSvg, foreign: fishingForSvg, foreignPkk: fishingForPkkSvg },
		{ type: "tanker", domestic: tankerSvg, domesticPkk: tankerPkkSvg, foreign: tankerForSvg, foreignPkk: tankerForPkkSvg },
		{ type: "cargo", domestic: cargoSvg, domesticPkk: cargoPkkSvg, foreign: cargoForSvg, foreignPkk: cargoForPkkSvg },
		{ type: "rescue", domestic: rescueSvg, domesticPkk: rescuePkkSvg, foreign: rescueForSvg, foreignPkk: rescueForPkkSvg },
		{ type: "tug", domestic: tugSvg, domesticPkk: tugPkkSvg, foreign: tugForSvg, foreignPkk: tugForPkkSvg },
		{ type: "military", domestic: militarySvg, domesticPkk: militaryPkkSvg, foreign: militaryForSvg, foreignPkk: militaryForPkkSvg },
		{ type: "towing", domestic: towingSvg, domesticPkk: towingPkkSvg, foreign: towingForSvg, foreignPkk: towingForPkkSvg },
		{ type: "high-speed", domestic: highSpeedSvg, domesticPkk: highSpeedPkkSvg, foreign: highSpeedForSvg, foreignPkk: highSpeedForPkkSvg },
		{ type: "unspecified reserved", domestic: unspecifiedSvg, domesticPkk: unspecifiedPkkSvg, foreign: unspecifiedForSvg, foreignPkk: unspecifiedForPkkSvg },
		{ type: "special", domestic: specialSvg, domesticPkk: specialPkkSvg, foreign: specialForSvg, foreignPkk: specialForPkkSvg },
		{ type: "wing", domestic: wingSvg, domesticPkk: wingPkkSvg, foreign: wingForSvg, foreignPkk: wingForPkkSvg },
		{ type: "bst", domestic: bstSvg, domesticPkk: bstPkkSvg, foreign: bstForSvg, foreignPkk: bstForPkkSvg },
	]

  const vesselType = shipType.toLowerCase();
	for (let i = 0; i < shipTypes.length; i++) {
		const el = shipTypes[i];
		if (vesselType.includes(el.type)) {
			if (isIndonesia) {
				if (isPkk) return el.domesticPkk
				return el.domestic
			}
			if (isPkk) return el.foreignPkk
			return el.foreign
		}
	}
  return shipIcon;
}